<template>
  <div class="logout">
    <div class="logout-container">
        <div class="text-wrapper">
          <p>
            Queres fazer parte do Rise? Responde a este formulário e a RISE WORK
            entrará em contacto contigo
          </p>
        </div>
        <div class="card-wrapper">
          <div class="logout-empresa">
            <img src="@/assets/img/company.svg" />
            <h4>Empresa</h4>
          </div>
          <div class="logout-profissional">
            <img src="@/assets/img/employee2.svg" />
            <h4>Profissional</h4>
          </div>
        </div>
    </div>
    <div class="toobar-container">
        <toobar-search hide-save="flex"/>
    </div>
  </div>
</template>

<script>
import ToobarSearch from '@/components shared/ToobarSearch/toobar-search'
export default {
  name: 'LogoutComponent',
  components: {
    'toobar-search': ToobarSearch
  }
}
</script>

<style lang="scss" scoped>
.logout{
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: #4B335A;
  color: #FFFFFF;
  .logout-container{
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-wrapper{
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    img{
      width: 50px;
      height: auto;
    }
  }
  .logout-empresa{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     border-radius: 10px;
      width: 250px;
      height: 250px;
      margin: 10px 0;
      background: #503660;
      opacity: 1;
  }
  .logout-profissional{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 10px;
      width: 250px;
      height: 250px;
      margin: 10px 0;
      background: #6A4C7C;
      opacity: 1;
  }
  .text-wrapper{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    p{
     text-align: left;
     max-width: 330px;
     margin-left:0;
     margin-bottom: 10px;
     opacity: 0.45;
    }
  }
  h4{
    color:#FFFFFF;
    margin: 10px 0;
    font-family: "NexaLight", sans-serif;
    opacity: 1;
    letter-spacing: 0;
  }
  .toobar-container{
    width: 100%;
    height: auto;
    padding: 0 10%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
  }
 }
</style>
