<template>
  <div>
    <home-logout/>
  </div>
</template>

<script>
import Logout from '@/components/Auth/Logout.vue'
export default {
  name: 'Logout',
  components: {
    'home-logout': Logout
  }
}
</script>
